import { ChainId } from '@jadeswap009/sdk'
import tokens from './tokens'
import { NewPoolConfig } from './types'

const newPool: NewPoolConfig[] = [
  // {
  //   pid: 252, // this will be used for prices only
  //   lpSymbol: 'USDC-wMNT',
  //   lpAddresses: {
  //     [ChainId.TESTNET]: '0xdF11a57F05331FdD9545550bd6Ee03E18C9953C2',
  //   },
  //   token: tokens.usdc,
  //   quoteToken: tokens.weth,
  //   notFetch: true,
  // },
  // {
  //   pid: 251, // this will be used for prices only
  //   lpSymbol: 'JadeSwap-WBIT',
  //   lpAddresses: {
  //     [ChainId.TESTNET]: '0x35ef42247703AE484919f0eF47b65203D50f2D8e',
  //   },
  //   token: tokens.zk,
  //   quoteToken: tokens.weth,
  //   notFetch: true,
  // },
  // {
  //   pid: 0,
  //   lpSymbol: 'JADE',
  //   lpAddresses: {
  //     [ChainId.MAINNET]: '0x7c70229F108D3d506Cff8Ea243FFA57344Fc4cE1',
  //     [ChainId.TESTNET]: '0xa779fCEb35f1b329Df69159b99F0b022f1EB1d48',
  //   },
  //   token: tokens.zk,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 1,
  //   lpSymbol: 'MNT',
  //   lpAddresses: {
  //     [ChainId.MAINNET]: tokens.weth.address[ChainId.MAINNET],
  //     [ChainId.TESTNET]:'0x8734110e5e1dcF439c7F549db740E546fea82d66',
  //   },
  //   token: tokens.weth,
  //   quoteToken: tokens.usdc,
  // },
]

export default newPool
