import { ChainId } from '@jadeswap009/sdk'
import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 1

export const BASE_MANT_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://explorer.mantle.xyz/',
  [ChainId.TESTNET]: 'https://explorer.testnet.mantle.xyz/',
}

export const BASE_ZKSYNC_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://explorer.zksync.io/',
  [ChainId.TESTNET]: 'https://goerli.explorer.zksync.io/',
}

// SWEET_PER_BLOCK details
// 40 JADEis minted per block
// 20 JADE per block is sent to Burn pool (A farm just for burning JADE)
// 10 JADE per block goes to JADEsyrup pool
// 9 JADE per block goes to Yield farms and lottery
// SWEET_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// JADE/Block in src/views/Home/components/SWEETDataRow.tsx = 17 (40 - Amount sent to burn pool)
// export const SWEET_PER_BLOCK = new BigNumber(40)
export const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
// export const SWEET_PER_YEAR = SWEET_PER_BLOCK.times(BLOCKS_PER_YEAR)
// TODO:UPDATE BASE URL
export const BASE_URL = 'http://localhost:3000'
// export const BASE_URL = 'https://panJadeSwap.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_LIQUIDITY_POOL_URL = `${BASE_URL}/pool`
export const BASE_ARBI_SCAN_URL = BASE_MANT_SCAN_URLS[ChainId.MAINNET]
export const BASE_ZKSYNC_SCAN_URL = BASE_ZKSYNC_SCAN_URLS[ChainId.MAINNET]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 200000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
