/* eslint-disable import/no-cycle */
// TODO: need to solve
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import isArchivedPid from 'utils/farmHelpers'
import priceHelperLpsConfig from 'config/constants/priceHelperLps'
import { Address } from 'config/constants/types'
import { ChainId } from '@jadeswap009/sdk'
import { newPoolsConfig } from 'config/constants'
import { getAddress } from 'utils/addressHelpers'
import fetchFarms from './fetchFarms'
import fetchFarmsPrices from './fetchFarmsPrices'
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
} from './fetchFarmUser'
import { NewPool, NewPoolState } from '../types'

const noAccountFarmConfig = newPoolsConfig.map((farm) => ({
  ...farm,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0',
  },
}))

const initialState: NewPoolState = { data: noAccountFarmConfig, loadArchivedFarmsData: false, userDataLoaded: false }

export const nonArchivedFarms = newPoolsConfig.filter(({ pid }) => !isArchivedPid(pid))

// Async thunks
export const fetchNewPoolsPublicDataAsync = createAsyncThunk<NewPool[], string[]>(
  'newPools/fetchNewPoolsPublicDataAsync',
  async (lpAddresses: string[]) => {
    // const farmsToFetch = newPoolsConfig.filter((farmConfig) => lpAddresses.includes(farmConfig.pid))
    const farmsToFetch = newPoolsConfig.filter((farmConfig) =>
      Object.values(farmConfig.lpAddresses).some((lp) => lpAddresses.includes(lp)),
    )
    // Add price helper farms
    // const farmsWithPriceHelpers = farmsToFetch.concat(priceHelperLpsConfig)
    const farmsWithPriceHelpers = farmsToFetch
    const farms = await fetchFarms(farmsWithPriceHelpers)
    const farmsWithPrices = await fetchFarmsPrices(farms)
    // const farmsWithPrices = farms
    // Filter out price helper LP config farms
    const farmsWithoutHelperLps = farmsWithPrices
    //   .filter((farm: NewPool) => {
    //   return farm.pid || farm.pid === 0
    // })

    return farmsWithoutHelperLps
  },
)

interface FarmUserDataResponse {
  lpAddress: string
  allowance: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
}

export const fetchFarmUserDataAsync = createAsyncThunk<
  FarmUserDataResponse[],
  {
    account: string
    lpAddresses: string[]
  }
>('farms/fetchFarmUserDataAsync', async ({ account, lpAddresses }) => {
  const farmsToFetch = newPoolsConfig.filter((farmConfig) =>
    Object.values(farmConfig.lpAddresses).some((lp) => lpAddresses.includes(lp)),
  )
  const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch)
  const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch)
  const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch)
  const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch)

  return userFarmAllowances.map((farmAllowance, index) => {
    return {
      lpAddress: getAddress(farmsToFetch[index].lpAddresses),
      allowance: userFarmAllowances[index],
      tokenBalance: userFarmTokenBalances[index],
      stakedBalance: userStakedBalances[index],
      earnings: userFarmEarnings[index],
    }
  })
})

export const NewPools = createSlice({
  name: 'NewPools',
  initialState,
  reducers: {
    setLoadArchivedFarmsData: (state, action) => {
      const loadArchivedFarmsData = action.payload
      state.loadArchivedFarmsData = loadArchivedFarmsData
    },
  },
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchNewPoolsPublicDataAsync.fulfilled, (state, action) => {
      state.data = state.data.map((farm) => {
        const liveFarmData = action.payload.find(
          (farmData) => getAddress(farmData.lpAddresses) === getAddress(farm.lpAddresses),
        )
        return { ...farm, ...liveFarmData }
      })
    })

    // Update farms with user data
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { lpAddress } = userDataEl
        const index = state.data.findIndex((farm) => getAddress(farm.lpAddresses) === lpAddress)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
  },
})

// Actions
export const { setLoadArchivedFarmsData } = NewPools.actions

export default NewPools.reducer
