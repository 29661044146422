import React from 'react'
import { useWeb3React } from '@web3-react/core'
import {
  Button,
  Flex,
  LogoIcon,
  LogoutIcon,
  Text,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  Skeleton,
  Box,
  ZksyncLogo,
  Image,
} from '@jadeswap009/uikit'
import useAuth from 'hooks/useAuth'
import BigNumber from 'bignumber.js'
// import { useProfile } from 'state/profile/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import { useTranslation } from 'contexts/Localization'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import RecentTransaction from 'components/App/Transactions/RecentTransaction'
import useZkMeta from 'hooks/useZkMeta'
import WalletModal, { WalletView, LOW_BNB_BALANCE } from './WalletModal'
// import ProfileUserMenuItem from './ProfileUserMenutItem'
import WalletUserMenuItem from './WalletUserMenuItem'

const UserMenu = () => {
  const { zkPriceDecimal } = useZkMeta()

  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { logout } = useAuth()
  // const { balance, fetchStatus } = useGetBnbBalance()
  // const { isInitialized, isLoading, profile } = useProfile()
  // const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  // const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  // const hasProfile = isInitialized && !!profile
  // const avatarSrc = profile && profile.nft ? `/images/nfts/${profile.nft.images.sm}` : undefined
  // const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)

  const SpaceRight = styled.div`
    margin-right: 8px;
  `
  const ArbiText = styled(Text)`
    display: none;
    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
    }
  `

  const StyledBuyButtonLink = styled(Link)`
    display: none;
    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
    }
  `

  const ArbiturnButton = styled(Box)`
    display: none;

    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
    }
  `

  const TokenPriceComp = () => {
    const theme = useTheme()
    return (
      <>
        <Button
          style={{ height: '45px', padding: '0 10px' }}
          mr={['0', null, null, '20']}
          scale="md"
          variant="secondary"
          // onClick={() => {
          //   onPresentAccountModal();
          // }}
        >
          <SpaceRight>
            <Box width="100%" minWidth={20}>
              <Image src="/jadeswapicon.svg" alt="swap" width={20} height={20} />
            </Box>
          </SpaceRight>
          <SpaceRight>
            <Text fontSize="1rem" fontWeight={500} color="primary">
              {new BigNumber(zkPriceDecimal).isNaN() ? <Skeleton width={20} height={20} /> : `$ ${zkPriceDecimal}`}
            </Text>
          </SpaceRight>
          <StyledBuyButtonLink to="/swap">
            <Button
              // color={theme?.colors.gradients.blue}
              size={15}
              variant="primary"
              style={{
                fontSize: '10px',
                padding: '15px 23px',
                borderRadius: '15px',
                background: theme?.colors.gradients.blue,
              }}
            >
              Buy
            </Button>
          </StyledBuyButtonLink>
        </Button>
      </>
    )
  }

  if (!account) {
    return (
      <Flex flexWrap="wrap" justifyContent={['end', null, null, '']} style={{ gap: '5px' }}>
        <ArbiturnButton>
          <Button
            variant="subtle"
            // borderRadius="5px"
            style={{ background: 'rgba(25, 105, 255, 0.1)', height: '45px', padding: '10px 15px' }}
            mr={['0', null, null, '10']}
            scale="md"
            onClick={() => {
              // onPresentConnectModal();
            }}
          >
            {/* <ZksyncLogo width={30} /> */}
            <img
              src="https://res.cloudinary.com/dizppn4hy/image/upload/v1690006693/mantle_eqkgiw.png"
              alt="swap"
              width={30}
              height={30}
            />

            <ArbiText fontSize="16px" color="primary" ml={2}>
              Mantle
            </ArbiText>
          </Button>
        </ArbiturnButton>
        <TokenPriceComp />
        <ConnectWalletButton style={{ padding: '10px 25px', height: '45px' }} scale="md" />
      </Flex>
    )
  }

  return (
    <UIKitUserMenu
      account={account}
      network="mantle"
      cakePriceUsd={zkPriceDecimal}
      logout={logout}
      recentTransaction={<RecentTransaction />}
      explerorURl="https://explorer.testnet.mantle.xyz"
      // avatarSrc={avatarSrc}
    />
  )
}

export default UserMenu
