import React from 'react'
import { Button, useWalletModal } from '@jadeswap009/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout)

  return (
    <>
      <Button onClick={onPresentConnectModal} borderRadius="10px" {...props}>
        {t('Connect')}
      </Button>
    </>
  )
}

export default ConnectWalletButton
