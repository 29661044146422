import React from 'react'
import styled from 'styled-components'
import { LogoIcon, Spinner } from '@jadeswap009/uikit'
import Loading from 'components/Loading'
import Page from '../Layout/Page'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      {/* <LogoIcon width="100px" /> */}
      {/* <Spinner /> */}
      <Loading>
        <LogoIcon width={50} />
      </Loading>
    </Wrapper>
  )
}

export default PageLoader
