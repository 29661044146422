import { createGlobalStyle } from 'styled-components'

// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@jadeswap009/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Plus Jakarta Display";
  src: local("PlusJakartaDisplay-Regular"),
    url("../font/PlusJakartaDisplay-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Plus Jakarta Display";
  src: local("PlusJakartaDisplay-Medium"),
    url("../font/PlusJakartaDisplay-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Plus Jakarta Display";
  src: local("PlusJakartaDisplay-Bold"),
    url("../font/PlusJakartaDisplay-Bold.woff2") format("woff2");
}
    
  body {
    background-color: ${({ theme }) => theme.colors.background};
    background: url("images/main-background-white.svg") repeat 
    font-family: "GT Walsheim Pro Regular Regular" !important;

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
