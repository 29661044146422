import React from 'react'
import styled from 'styled-components'
import { Text, Flex, Heading, IconButton, ArrowBackIcon, Box, NotificationDot, LogoIcon } from '@jadeswap009/uikit'
import { Link } from 'react-router-dom'
import { useExpertModeManager } from 'state/user/hooks'
import GlobalSettings from 'components/Menu/GlobalSettings'
import Transactions from './Transactions'
import QuestionHelper from '../QuestionHelper'

interface Props {
  title: string
  subtitle: string
  helper?: string
  backTo?: string
  noConfig?: boolean
}
const BorderBottomLine = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.textDisabled};
  width: 86%;
  height: 1px;
  margin: 0 auto;
`
const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor}; */
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  const [expertMode] = useExpertModeManager()

  return (
    <>
      <AppHeaderContainer>
        <Flex alignItems="center" mr={noConfig ? 0 : '16px'}>
          {backTo && (
            <IconButton as={Link} to={backTo}>
              <ArrowBackIcon width="32px" />
            </IconButton>
          )}
          <Flex flexDirection="column">
            <Flex style={{gap:"10px"}}>
              <LogoIcon className="mobile-icon" width={20} />
              <Box>
                <Heading as="h2" fontSize="24px" style={{ fontSize: '24px' }}>
                  {title}
                </Heading>
                <Flex alignItems="center">
                  <Text color="textSubtle" fontWeight={500} fontSize="14px">
                    {subtitle}
                  </Text>
                  {helper && <QuestionHelper text={helper} mr="4px" />}
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        {!noConfig && (
          <Flex alignItems="center">
            <NotificationDot show={expertMode}>
              <GlobalSettings />
            </NotificationDot>
            {/* <Transactions /> */}
          </Flex>
        )}
      </AppHeaderContainer>
      <BorderBottomLine />
    </>
  )
}

export default AppHeader
