import styled from 'styled-components'

const Loading = styled.div`
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(3600deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`

export default Loading
