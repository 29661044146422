import { ChainId } from '@jadeswap009/sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useActiveListUrls } from 'state/lists/hooks'

export const getAddress = (address: Address): string => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
  console.log('getAddress', chainId, address)
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getCakeAddress = () => {
  return getAddress(tokens.cake.address)
}
export const getReferralAddress = () => {
  return addresses.referral[ChainId.MAINNET]
}
export const getZkAddress = () => {
  return addresses.zks[ChainId.MAINNET]
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getZkBoosterAddress = () => {
  return getAddress(addresses.zkBooster)
}
export const getICOAddress = () => {
  return getAddress(addresses.ico)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getMulticall3Address = () => {
  return getAddress(addresses.multiCall2)
}
export const getWbnbAddress = () => {
  return getAddress(tokens.wbnb.address)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
