import { MenuEntry } from '@jadeswap009/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Swap',
    icon: 'SwapIcon',
    href: '/swap',
  },
  {
    label: 'Yield',
    icon: 'TradeIcon',
    // href: 'farms' || 'pools',
    items: [
      {
        label: 'Farms',
        href: '/farm',
      },

      {
        label: 'Pools',
        href: 'stake',
      },
    ],
  },

  {
    label: 'Referral',
    icon: 'ReferralIcon',
    href: '/referral_program',
  },
  {
    label: 'Docs',
    icon: 'DocsIcon',
    href: 'https://jadeswap.gitbook.io/jadeswap/',
  },
]

export default config
