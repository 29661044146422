import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@jadeswap009/uikit'
import { useTranslation } from 'contexts/Localization'

const StyledNav = styled.nav`
  margin-bottom: 40px;
`

const getActiveIndex = (pathname: string): number => {
  if (
    pathname.includes('/pool') ||
    pathname.includes('/create') ||
    pathname.includes('/add') ||
    pathname.includes('/remove') ||
    pathname.includes('/find') ||
    pathname.includes('/liquidity')
  ) {
    return 1
  }
  return 0
}

const Nav = () => {
  const location = useLocation()
  const { t } = useTranslation()
  return (
    <StyledNav>
      <ButtonMenu activeIndex={getActiveIndex(location.pathname)} scale="md" variant="primary">
        <ButtonMenuItem
          style={{ padding: '27px 30px', width: '130px', fontWeight: 'bolder' }}
          id="swap-nav-link"
          to="/swap"
          as={Link}
        >
          {t('Swap')}
        </ButtonMenuItem>
        <ButtonMenuItem
          style={{ padding: '27px 30px', width: '130px', fontWeight: 'bolder' }}
          id="pool-nav-link"
          to="/pool"
          as={Link}
        >
          {t('Liquidity')}
        </ButtonMenuItem>
      </ButtonMenu>
    </StyledNav>
  )
}

export default Nav
