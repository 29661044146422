// import { Interface, FunctionFragment } from '@ethersproject/abi'
// import { BigNumber } from '@ethersproject/bignumber'
// import { Contract } from '@ethersproject/contracts'
// import { useEffect, useMemo } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import { useBlock } from 'state/block/hooks'
// // eslint-disable-next-line camelcase
// import { unstable_serialize, useSWRConfig } from 'swr'
// import { AppDispatch, AppState } from '../index'

// import {
//   addMulticallListeners,
//   Call,
//   removeMulticallListeners,
//   parseCallKey,
//   toCallKey,
//   ListenerOptions,
// } from './actions'

// export interface MultiContractsMultiMethodsCallInput {
//   contract: Contract | null | undefined
//   methodName: string
//   inputs?: OptionalMethodInputs
// }

// export interface Result extends ReadonlyArray<any> {
//   readonly [key: string]: any
// }

// type MethodArg = string | number | BigNumber
// type MethodArgs = Array<MethodArg | MethodArg[]>

// type OptionalMethodInputs = Array<MethodArg | MethodArg[] | undefined> | undefined

// function isMethodArg(x: unknown): x is MethodArg {
//   return ['string', 'number'].indexOf(typeof x) !== -1
// }

// function isValidMethodArgs(x: unknown): x is MethodArgs | undefined {
//   return (
//     x === undefined ||
//     (Array.isArray(x) && x.every((xi) => isMethodArg(xi) || (Array.isArray(xi) && xi.every(isMethodArg))))
//   )
// }

// interface CallResult {
//   readonly valid: boolean
//   readonly data: string | undefined
//   readonly blockNumber: number | undefined
// }

// const INVALID_RESULT: CallResult = { valid: false, blockNumber: undefined, data: undefined }

// // use this options object
// export const NEVER_RELOAD: ListenerOptions = {
//   blocksPerFetch: Infinity,
// }

// // the lowest level call for subscribing to contract data
// function useCallsData(calls: (Call | undefined)[], options?: ListenerOptions): CallResult[] {
//   const { chainId } = useActiveWeb3React()
//   const callResults = useSelector<AppState, AppState['multicall']['callResults']>(
//     (state) => state.multicall.callResults,
//   )
//   const dispatch = useDispatch<AppDispatch>()

//   const serializedCallKeys: string = useMemo(
//     () =>
//       JSON.stringify(
//         calls
//           ?.filter((c): c is Call => Boolean(c))
//           ?.map(toCallKey)
//           ?.sort() ?? [],
//       ),
//     [calls],
//   )

//   // update listeners when there is an actual change that persists for at least 100ms
//   useEffect(() => {
//     const callKeys: string[] = JSON.parse(serializedCallKeys)
//     if (!chainId || callKeys.length === 0) return undefined
//     // eslint-disable-next-line @typescript-eslint/no-shadow
//     const calls = callKeys.map((key) => parseCallKey(key))
//     dispatch(
//       addMulticallListeners({
//         chainId,
//         calls,
//         options,
//       }),
//     )

//     return () => {
//       dispatch(
//         removeMulticallListeners({
//           chainId,
//           calls,
//           options,
//         }),
//       )
//     }
//   }, [chainId, dispatch, options, serializedCallKeys])

//   return useMemo(
//     () =>
//       calls.map<CallResult>((call) => {
//         if (!chainId || !call) return INVALID_RESULT

//         const result = callResults[chainId]?.[toCallKey(call)]
//         let data
//         if (result?.data && result?.data !== '0x') {
//           // eslint-disable-next-line prefer-destructuring
//           data = result.data
//         }

//         return { valid: true, data, blockNumber: result?.blockNumber }
//       }),
//     [callResults, calls, chainId],
//   )
// }

// interface CallState {
//   readonly valid: boolean
//   // the result, or undefined if loading or errored/no data
//   readonly result: Result | undefined
//   // true if the result has never been fetched
//   readonly loading: boolean
//   // true if the result is not for the latest block
//   readonly syncing: boolean
//   // true if the call was made and is synced, but the return data is invalid
//   readonly error: boolean
// }

// const INVALID_CALL_STATE: CallState = { valid: false, result: undefined, loading: false, syncing: false, error: false }
// const LOADING_CALL_STATE: CallState = { valid: true, result: undefined, loading: true, syncing: true, error: false }

// // function toCallState(
// //   callResult: CallResult | undefined,
// //   contractInterface: Interface | undefined,
// //   fragment: FunctionFragment | undefined,
// //   latestBlockNumber: number | undefined,
// //   methodName: string, // added parameter for method name
// // ): CallState {
// //   if (!callResult) return INVALID_CALL_STATE
// //   const { valid, data, blockNumber } = callResult
// //   if (!valid) return INVALID_CALL_STATE
// //   if (valid && !blockNumber) return LOADING_CALL_STATE
// //   if (!contractInterface || !fragment || !latestBlockNumber) return LOADING_CALL_STATE
// //   const success = data && data.length > 2
// //   const syncing = (blockNumber ?? 0) < latestBlockNumber
// //   let result: Result | undefined
// //   if (success && data) {
// //     try {
// //       result = contractInterface.decodeFunctionResult(fragment, data)
// //     } catch (error) {
// //       console.debug('Result data parsing failed', fragment, data)
// //       return {
// //         valid: true,
// //         loading: false,
// //         error: true,
// //         syncing,
// //         result,
// //       }
// //     }
// //   }
// //   return {
// //     valid: true,
// //     loading: false,
// //     syncing,
// //     result,
// //     error: !success,
// //   }
// // }

// function toCallState(
//   callResult: CallResult | undefined,
//   contractInterface: Interface | undefined,
//   fragment: FunctionFragment | undefined,
//   latestBlockNumber: number | undefined,
//   // methodName?: string,
// ): CallState {
//   if (!callResult) return INVALID_CALL_STATE
//   const { valid, data, blockNumber } = callResult
//   if (!valid) return INVALID_CALL_STATE
//   if (valid && !blockNumber) return LOADING_CALL_STATE
//   if (!contractInterface || !fragment || !latestBlockNumber) return LOADING_CALL_STATE
//   const success = data && data.length > 2
//   const syncing = (blockNumber ?? 0) < latestBlockNumber
//   let result: Result | undefined
//   if (success && data) {
//     try {
//       result = contractInterface.decodeFunctionResult(fragment, data)
//     } catch (error) {
//       console.debug('Result data parsing failed', fragment, data)
//       return {
//         valid: true,
//         loading: false,
//         error: true,
//         syncing,
//         result,
//       }
//     }
//   }
//   return {
//     valid: true,
//     loading: false,
//     syncing,
//     result,
//     error: !success,
//   }
// }

// export function useSingleContractMultipleData(
//   contract: Contract | null | undefined,
//   methodName: string,
//   callInputs: OptionalMethodInputs[],
//   options?: ListenerOptions,
// ): CallState[] {
//   const fragment = useMemo(() => contract?.interface?.getFunction(methodName), [contract, methodName])

//   const calls = useMemo(
//     () =>
//       contract && fragment && callInputs && callInputs.length > 0
//         ? callInputs.map<Call>((inputs) => {
//             return {
//               address: contract.address,
//               callData: contract.interface.encodeFunctionData(fragment, inputs),
//             }
//           })
//         : [],
//     [callInputs, contract, fragment],
//   )

//   const results = useCallsData(calls, options)

//   const { currentBlock } = useBlock()

//   return useMemo(() => {
//     return results.map((result) => toCallState(result, contract?.interface, fragment, currentBlock))
//   }, [fragment, contract, results, currentBlock])
// }

// export function useMultipleContractSingleData(
//   addresses: (string | undefined)[],
//   contractInterface: Interface,
//   methodName: string,
//   callInputs?: OptionalMethodInputs,
//   options?: ListenerOptions,
// ): CallState[] {
//   const fragment = useMemo(() => contractInterface.getFunction(methodName), [contractInterface, methodName])
//   const callData: string | undefined = useMemo(
//     () =>
//       fragment && isValidMethodArgs(callInputs)
//         ? contractInterface.encodeFunctionData(fragment, callInputs)
//         : undefined,
//     [callInputs, contractInterface, fragment],
//   )

//   const calls = useMemo(
//     () =>
//       fragment && addresses && addresses.length > 0 && callData
//         ? addresses.map<Call | undefined>((address) => {
//             return address && callData
//               ? {
//                   address,
//                   callData,
//                 }
//               : undefined
//           })
//         : [],
//     [addresses, callData, fragment],
//   )

//   const results = useCallsData(calls, options)

//   const { currentBlock } = useBlock()

//   return useMemo(() => {
//     return results.map((result) => toCallState(result, contractInterface, fragment, currentBlock))
//   }, [fragment, results, contractInterface, currentBlock])
// }

// export function useSingleCallResult(
//   contract: Contract | null | undefined,
//   methodName: string,
//   inputs?: OptionalMethodInputs,
//   options?: ListenerOptions,
// ): CallState {
//   const fragment = useMemo(() => contract?.interface?.getFunction(methodName), [contract, methodName])

//   const calls = useMemo<Call[]>(() => {
//     return contract && fragment && isValidMethodArgs(inputs)
//       ? [
//           {
//             address: contract.address,
//             callData: contract.interface.encodeFunctionData(fragment, inputs),
//           },
//         ]
//       : []
//   }, [contract, fragment, inputs])

//   const result = useCallsData(calls, options)[0]
//   const { currentBlock } = useBlock()

//   return useMemo(() => {
//     return toCallState(result, contract?.interface, fragment, currentBlock)
//   }, [result, contract, fragment, currentBlock])
// }

// // TODO: iteration warning in this code
// export function useMultiContractsMultiMethods(
//   callInputs: MultiContractsMultiMethodsCallInput[],
//   options?: ListenerOptions,
// ) {
//   const { chainId } = useActiveWeb3React()

//   const { calls, fragments, contracts } = useMemo(() => {
//     if (!callInputs || !callInputs.length) {
//       return { calls: [], fragments: [], contracts: [] }
//     }
//     const validFragments: FunctionFragment[] = []
//     const validContracts: Contract[] = []
//     const validCalls: Call[] = []
//     // eslint-disable-next-line no-restricted-syntax
//     for (const { methodName, inputs, contract } of callInputs) {
//       const fragment = contract?.interface.getFunction(methodName)
//       if (!contract || !fragment) {
//         // eslint-disable-next-line no-continue
//         continue
//       }
//       validFragments.push(fragment)
//       validContracts.push(contract)
//       validCalls.push({
//         address: contract.address,
//         callData: contract.interface.encodeFunctionData(fragment, inputs),
//       })
//     }
//     return { calls: validCalls, fragments: validFragments, contracts: validContracts }
//   }, [callInputs])

//   const results = useCallsData(calls, options)

//   // const { cache } = useSWRConfig()

//   const { currentBlock } = useBlock()

//   return useMemo(() => {
//     // const currentBlockNumber = cache.get(unstable_serialize(['blockNumber', chainId]))?.data
//     return results.map((result, i) => toCallState(result, contracts[i]?.interface, fragments[i], currentBlock))
//   }, [currentBlock, results, fragments, contracts])
// }

// // export function useMultiContractsMultiMethods(
// //   callInputs: MultiContractsMultiMethodsCallInput[],
// //   options?: ListenerOptions,
// // ) {
// //   const { chainId } = useActiveWeb3React()

// //   const { calls, fragments, contracts } = useMemo(() => {
// //     if (!callInputs || !callInputs.length) {
// //       return { calls: [], fragments: [], contracts: [] }
// //     }

// //     // Use Array.map() to transform each callInput into an object containing the
// //     // fragment, contract, and encoded call data
// //     const callObjects = callInputs.map((callInput) => {
// //       const { methodName, inputs, contract } = callInput

// //       const fragment = contract?.interface.getFunction(methodName)
// //       if (!contract || !fragment) {
// //         return null
// //       }

// //       const callData = contract.interface.encodeFunctionData(fragment, inputs)

// //       return {
// //         fragment,
// //         contract,
// //         call: {
// //           address: contract.address,
// //           callData,
// //         },
// //       }
// //     })

// //     // Use Array.reduce() to combine the call objects into separate arrays for
// //     // calls, fragments, and contracts
// //     const { calls, fragments, contracts } = callObjects.reduce(
// //       (acc, callObject) => {
// //         if (!callObject) {
// //           return acc
// //         }

// //         return {
// //           calls: [...acc.calls, callObject.call],
// //           fragments: [...acc.fragments, callObject.fragment],
// //           contracts: [...acc.contracts, callObject.contract],
// //         }
// //       },
// //       { calls: [], fragments: [], contracts: [] },
// //     )

// //     return { calls, fragments, contracts }
// //   }, [callInputs])

// //   const results = useCallsData(calls, options)

// //   const { cache } = useSWRConfig()

// //   return useMemo(() => {
// //     const currentBlockNumber = cache.get(unstable_serialize(['blockNumber', chainId]))?.data
// //     return results.map((result, i) => toCallState(result, contracts[i]?.interface, fragments[i], currentBlockNumber))
// //   }, [cache, chainId, results, fragments, contracts])
// // }

// export function useSingleContractMultiMethods(
//   contract: Contract | null | undefined,
//   callInputs: {
//     methodName: string
//     inputs?: OptionalMethodInputs
//   }[],
//   options?: ListenerOptions,
// ) {
//   const multiInputs = useMemo(() => callInputs.map((callInput) => ({ ...callInput, contract })), [callInputs, contract])
//   return useMultiContractsMultiMethods(multiInputs, options)
// }
import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import useBlockNumber from 'lib/hooks/useBlockNumber'
// import multicall from 'lib/state/multicall'
import { useBlock } from 'state/block/hooks'
// import { SkipFirst } from 'types/tuple'
import multicall from './updater'

type TupleSplit<T, N extends number, O extends readonly any[] = readonly []> = O['length'] extends N
  ? [O, T]
  : T extends readonly [infer F, ...infer R]
  ? TupleSplit<readonly [...R], N, readonly [...O, F]>
  : [O, T]

export type SkipFirst<T extends readonly any[], N extends number> = TupleSplit<T, N>[1]

export type { CallStateResult } from '@uniswap/redux-multicall' // re-export for convenience
export { NEVER_RELOAD } from '@uniswap/redux-multicall' // re-export for convenience

// Create wrappers for hooks so consumers don't need to get latest block themselves

type SkipFirstTwoParams<T extends (...args: any) => any> = SkipFirst<Parameters<T>, 2>

export function useMultipleContractSingleData(
  ...args: SkipFirstTwoParams<typeof multicall.hooks.useMultipleContractSingleData>
) {
  const { chainId, latestBlock } = useCallContext()
  return multicall.hooks.useMultipleContractSingleData(chainId, latestBlock, ...args)
}

export function useSingleCallResult(...args: SkipFirstTwoParams<typeof multicall.hooks.useSingleCallResult>) {
  const { chainId, latestBlock } = useCallContext()
  return multicall.hooks.useSingleCallResult(chainId, latestBlock, ...args)
}

export function useSingleContractMultipleData(
  ...args: SkipFirstTwoParams<typeof multicall.hooks.useSingleContractMultipleData>
) {
  const { chainId, latestBlock } = useCallContext()
  return multicall.hooks.useSingleContractMultipleData(chainId, latestBlock, ...args)
}

export function useSingleContractWithCallData(
  ...args: SkipFirstTwoParams<typeof multicall.hooks.useSingleContractWithCallData>
) {
  const { chainId, latestBlock } = useCallContext()
  return multicall.hooks.useSingleContractWithCallData(chainId, latestBlock, ...args)
}

function useCallContext() {
  const { chainId } = useActiveWeb3React()
  const { currentBlock: latestBlock } = useBlock()
  return { chainId, latestBlock }
}
