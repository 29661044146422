import { Currency, ETHER, Token } from '@jadeswap009/sdk'
import { EthereumIcon, LogoIcon } from '@jadeswap009/uikit'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import getTokenLogoURL from '../../utils/getTokenLogoURL'
import Logo from './Logo'
import { MantleLogo } from './MantleLogo'

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency.symbol.toLowerCase() === 'jade')
        return [
          '/images/tokens/0x7c70229F108D3d506Cff8Ea243FFA57344Fc4cE1.svg',
          '/images/tokens/0xa779fCEb35f1b329Df69159b99F0b022f1EB1d48.svg',
        ]
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }
      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    // Todo: change the icon on replace the line below
    return (
      <>
        <MantleLogo />
      </>
    )

    //
  }
  if (currency?.symbol.toLowerCase() === 'jade') {
    // Todo: change the icon on replace the line below
    return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />

    //
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
