import sample from 'lodash/sample'

// Array of available nodes to connect to
// export const nodes = [process.env.REACT_APP_NODE_1, process.env.REACT_APP_NODE_2, process.env.REACT_APP_NODE_3]
// export const nodes = ['https://rpc.testnet.mantle.xyz/']
export const nodes = ['https://rpc.mantle.xyz/']
const getNodeUrl = () => {
  return sample(nodes)
}

export default getNodeUrl
