import { useSingleCallResult } from 'state/multicall/hooks'
import { getBalanceAmount } from 'utils/formatBalance'
import { useZks, useMasterchef } from './useContract'
import { useCakeBusdPrice } from './useBUSDPrice'

const useSweetMeta = () => {
  const zkContract = useZks()
  const masterChefContract = useMasterchef()
  const { result: JadePerBlockResult } = useSingleCallResult(masterChefContract, 'JadePerBlock')
  const { result: totalBurned } = useSingleCallResult(zkContract, 'totalBurned')
  const { result: totalSuppy } = useSingleCallResult(zkContract, 'totalSupply')
  const totalZksBurned = (totalBurned && getBalanceAmount(totalBurned[0].toString()).toFixed(2).toString()) || 0

  const totalSupply = (totalSuppy && getBalanceAmount(totalSuppy[0].toString()).toFixed(0).toString()) || 0
  const price = useCakeBusdPrice()
  const zkPrice = price?.toSignificant(10)
  const zkPriceDecimal = Number(price?.toSignificant(10)).toFixed(5) || 0

  const JadePerBlock = JadePerBlockResult && Number(JadePerBlockResult[0]._hex.toString())

  return { totalZksBurned, totalSupply, price, zkPrice, zkPriceDecimal, JadePerBlock }
}

export default useSweetMeta
